import { render, staticRenderFns } from "./newOpinionButton.vue?vue&type=template&id=3ee7104e&"
import script from "./newOpinionButton.vue?vue&type=script&lang=js&"
export * from "./newOpinionButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSlider: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/AppSlider.vue').default})
