import { render, staticRenderFns } from "./ModuleProductSimilar.vue?vue&type=template&id=f3c0e120&scoped=true&"
import script from "./ModuleProductSimilar.vue?vue&type=script&lang=js&"
export * from "./ModuleProductSimilar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3c0e120",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleHeader: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/ModuleHeader.vue').default,AppCardsCarousel: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/AppCardsCarousel.vue').default,ModuleFooter: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/ModuleFooter.vue').default})
