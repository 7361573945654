const middleware = {}

middleware['Brands'] = require('../middleware/Brands.js')
middleware['Brands'] = middleware['Brands'].default || middleware['Brands']

middleware['Filters'] = require('../middleware/Filters.js')
middleware['Filters'] = middleware['Filters'].default || middleware['Filters']

middleware['Origin'] = require('../middleware/Origin.js')
middleware['Origin'] = middleware['Origin'].default || middleware['Origin']

middleware['Stores'] = require('../middleware/Stores.js')
middleware['Stores'] = middleware['Stores'].default || middleware['Stores']

export default middleware
