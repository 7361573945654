//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookieConsentCustom from '~/plugins/cookieconsent'
import project from '~/static/config/project.json'
import { customPageView } from '~/lib/gtm'

export default {
  name: 'LytDflt',
  head () {
    const metas = { meta: [] }

    if (project.meta?.snipped) {
      metas.meta.push({
        name: 'robots',
        content:
          'max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      })
    }

    if (project.meta?.noindex) {
      metas.meta.push({
        name: 'robots',
        content: 'noindex'
      })
    }

    return metas
  },
  mounted () {
    if (project.plugins?.cookieconsent) {
      cookieConsentCustom(
        {
          onAccept: (cookie) => {
            if (cookie.categories.includes('analytics')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAnalytics' })
              customPageView.apply(this, [document?.title])
            }
            if (cookie.categories.includes('marketing')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAd' })

              window.googletag?.cmd.push(function () {
                window.googletag.pubads().setPrivacySettings({
                  limitedAds: false,
                  nonPersonalizedAds: false,
                  restrictDataProcessing: false
                })
              })
            }
          }
        },
        this.$t('consent_cookie')
      )
    }

    customPageView.apply(this, [document?.title, false])
    this.$store.dispatch('gtm/consent')

    if (this.$route.query.gt_origen) {
      this.$cookiz.set('gt_origen', this.$route.query.gt_origen, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30
      })
    }
  }
}
