import { render, staticRenderFns } from "./default.vue?vue&type=template&id=624430cb&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFooter: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/AppFooter.vue').default,AppModal: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/AppModal.vue').default,AuthLoginModal: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/AuthLoginModal.vue').default,GeoLocalizatiosUser: require('/furanet/sites/us.runnea.com/web/htdocs/nuxt/components/GeoLocalizatiosUser.vue').default})
