//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ params, route, app, store, $axios, $md5, $config }) {
    // Params & Props
    const routeProps = route.matched[0].props.default
    // const TypeId = routeProps.prodTypeId
    // let Type = routeProps.Type

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/inquiry/zones.json'
    const zones = await $axios.get(call)
    await ax.createComponentData(zones.data, store)

    // Sections Data
    const sectQry = $config.static + '/json/questions/sections.json'
    const sectCode = $md5(sectQry)
    if (typeof store.state.lists[sectCode] === 'undefined' || Object.keys(store.state.lists[sectCode]).length === 0) {
      await store.dispatch('loadLists', sectQry)
    }
    const sections = store.state.lists[sectCode].filter((section) => {
      return section.active !== '0'
    })

    // Set Section
    let section = []
    sections.forEach((s) => {
      const er = new RegExp(`^${s.path}`)
      const match = er.exec(route.path)
      if (match) {
        section[match[0].length] = s
      }
    })
    section = section.pop()

    // BreadCrumb
    let breadcrumb = []
    if (section) {
      breadcrumb = breadcrumb.concat(routeProps.breadcrumb)
      breadcrumb.push({
        text: section.name,
        href: section.path,
        active: true
      })
    }

    // Busqueda texto
    let querySearch = ''
    if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
      querySearch = route.query.q
    }

    // Get inquiries API data
    let page = 1
    let productID = ''
    let filtersUrl = route.fullPath

    if (section && section?.path !== '') {
      filtersUrl = filtersUrl.replace(`${section.path}/`, '')
    }

    if (filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)) {
      const pathMatches = filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)
      page = (pathMatches[1] !== undefined) ? pathMatches[1] : pathMatches[2] !== undefined ? pathMatches[2] : ''
    }

    if (filtersUrl !== '' && filtersUrl.match(/^.+?-([0-9]+)\//)) {
      const pathMatches = filtersUrl.match(/^.+?-([0-9]+)\//)
      productID = pathMatches[1]
    }

    let product = false
    if (!store.state.productsLoaded) {
      await store.dispatch('loadProducts')
    }

    product = store.state.products.find(p => p.content_id === productID)
    let filters2api = ''
    if (section && section.content_id && productID === '' && section.id !== '') {
      filters2api += `/section-${section.content_id}`
    }

    const query = `${$config.api}/app/v1/users/chats?_p=${$config.id}&filters=${filters2api}&page=${page}&text=${querySearch}&products=${product?.content_id ?? ''}&order=date&direction=desc`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const inquiries = store.state.lists[code]

    // Metas & Head
    const producto = product ? ` ${product.brand} ${product.title}` : ''
    const dinamicSeo = section ? section.metas.title.replace(/#producto#/g, producto).replace(/#texto#/g, ` ${querySearch}`) : app.i18n.t('product_page.inquiries')

    const header = {
      h1: dinamicSeo
    }
    const head = {
      title: app.i18n.t('inquiry_list.meta_title').replace(/{seo}/g, dinamicSeo),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: app.i18n.t('inquiry_list.meta_title').replace(/{seo}/g, dinamicSeo)
        },
        {
          hid: 'description',
          name: 'description',
          content: (section && section.metas?.description !== '') ? section.metas.description : app.i18n.t('inquiry_list.meta_description')
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'inquiry_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      breadcrumb,
      inquiries,
      header,
      head,
      zones: zonesContent,
      sections,
      currentSection: section
    }
  },
  data () {
    return {
      sections: []
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
