export default {
  countries: {
    now: 'You are in',
    change: 'Change',
    name: {
      ES: 'Spain',
      FR: 'France',
      DE: 'Germany',
      IT: 'Italy',
      UK: 'Great Britain',
      MX: 'Mexico',
      PT: 'Portugal',
      EUR: 'Europe',
      AME: 'America',
      US: 'United States',
    }
  },
  global: {
    timeAgoMinutes: '{minutes} min ago',
    timeAgoHours: '{hours} h ago',
    timeAgoHoursMinutes: '{hours} h and {minutes} min ago',
    timeAgoDay: '1 day ago',
    timeAgoDays: '{days} days ago'
  },
  pages: {
    home: 'Home',
    leaf: 'Details',
    list: 'List',
    contacts: 'Contacts'
  },
  components: {
    ProductDescription: {
      readMore: 'Read more'
    },
    SliderCardFinder: {
      most_find: 'Most popular:'
    }
  },
  product_types: {
    1: 'Running shoes',
    45: 'Heart Rate Monitors & Sports Watches',
    71: 'Running pants',
    72: 'Sports bras',
    73: 'Fitness trackers',
    74: 'Smart watch',
    75: 'Bike computers',
    76: 'Sports headphones',
    84: 'Hiking shoes',
    102: 'Sunglasses',
    105: 'Gym equipment'
  },
  sections: {
    4: 'Running News',
    5: 'Running training',
    6: 'Nutrition',
    7: 'Sport injuries',
    15: 'Mizuno blog by Raquel',
    31: 'Sketchers athletes',
    32: 'Blogger Running fashion',
    45: 'Runnea podcast',
    58: 'New York Marathon',
    63: 'Mothers & Runners',
    71: 'Outdoor',
    73: "Runnea women's team",
    74: 'Electronics'
  },
  leaf: {
    features: {
      title: 'Characteristics',
      list: {
        gender: 'Gender',
        cushioning: 'Cushioning',
        constitution: 'Runner weight',
        footprint: 'Running gait',
        surface: 'Surface',
        use: 'Use',
        beat: 'Pace',
        foot: 'Foot',
        support: 'Strike',
        distance: 'Distance',
        weight: 'Weight',
        weightf: 'Women weight',
        weightm: 'Men weight',
        drop: 'Drop',
        droptie: 'Drop',
        colour: 'Color',
        options: 'Options',
        sensors: 'Sensors',
        connections: 'Connections',
        autonomy: 'Battery life (GPS Mode)',
        technology: 'Technology',
        impermeability: 'Waterproof',
        sole: 'Sole',
        fabric: 'Fabric',
        sustainability: 'Eco-friendly',
        year: 'Year'
      },
      values: {
        man: 'Men',
        woman: 'Women',
        low: 'Low',
        medium: 'Medium',
        high: 'High',
        short: 'Short',
        light: 'Light',
        strong: 'Strong',
        neutral: 'Neutral',
        supinator: 'Supination',
        pronator: 'Overpronation',
        asphalt: 'Road',
        trail: 'Trail',
        training: 'training',
        competition: 'Racing',
        mixed: 'mixed',
        dig: 'High arch feet',
        flat: 'Flat feet',
        normal: 'Normal feet',
        heel: 'heel',
        maraton: 'Marathon',
        'carbon-plate': 'Carbon plate',
        'half-maraton': 'Half-marathon',
        '10k': '10K',
        fitness: 'Fitness',
        running: 'Running',
        running_shoes: 'Running shoes',
        cycling: 'Cycling',
        swimming: 'Swimming/Diving',
        outdoor: 'Outdoor/Mountain',
        triathlon: 'Triathlon',
        crossfit: 'Crossfit',
        trekking: 'Hiking',
        gps: 'GPS',
        tactile: 'Touch screen',
        color_screen: 'Color screen',
        maps: 'Maps',
        pulse: 'Pulse on wrist',
        band: 'Chest strap',
        music: 'Offline Music',
        spotify: 'Spotify',
        strava: 'Strava',
        altimeter: 'Altimeter',
        thermometer: 'Thermometer',
        tensiometer: 'Sphygmomanometer',
        oximeter: 'Oximeter',
        barometer: 'Barometer',
        bluetooth: 'Bluetooth',
        wifi: 'WI-FI',
        nfc: 'NFC',
        fm: 'FM Radio',
        sim: 'SIM',
        '1-12': '1-12h',
        '13-24': '13-24h',
        '25-36': '25-36h',
        '37-48': '37-48h',
        48: 'More than 48 hours',
        yes: 'Yes',
        no: 'No',
        'dry-line': 'Dry-line',
        'gore-tex': 'Gore-tex',
        'omni-tech': 'Omni-tech',
        outdry: 'Outdry',
        contagrip: 'Contagrip',
        continental: 'Continental',
        frixion: 'Frixion',
        michelin: 'Michelin',
        vibram: 'Vibram',
        tiptoe: 'Forefoot',
        vegans: 'Vegan',
        'recycled-material': 'Recycled material',
        co2: 'CO2 sustainable',
        yellow: 'Yellow',
        blue: 'Blue',
        white: 'White',
        grey: 'Grey',
        brown: 'Brown',
        purple: 'Purple',
        orange: 'Orange',
        black: 'Black',
        red: 'Red',
        pink: 'Pink',
        green: 'Green'
      }
    },
    performances: {
      title: 'Performance',
      lightness: 'Lightness',
      cushioning: 'Cushioning',
      flexibility: 'Flexibility',
      responsive: 'Responsive',
      stability: 'Stability',
      grip: 'Grip',
      durability: 'Durability',
      fit: 'Fit',
      comfort: 'Comfort',
      quality: 'Quality'
    }
  },
  list: {
    filters: {
      title: 'Filters',
      brand: 'Brand',
      gender: 'Gender',
      price: 'Price',
      drop: 'Drop',
      droptie: 'Drop',
      cushioning: 'Cushion',
      constitution: 'Runner weight',
      footprint: 'Running gait',
      surface: 'Surface',
      use: 'Use',
      beat: 'Pace',
      foot: 'Foot',
      support: 'Strike',
      distance: 'Distance',
      sustainability: 'Eco-friendly',
      size: 'Sizes',
      technology: 'Technology',
      fabric: 'Fabric',
      store: 'Store',
      colour: 'Color',
      impermeability: 'Waterproof',
      sole: 'Sole',
      options: 'Options',
      type: 'Type',
      category: 'Category',
      lens_category: ' Lens Category',
      lens_type: 'Lens type',
      filter: 'Filters',
      province: 'Province',
      region: 'Region',
      country: 'Country',
      year: 'Year',
      month: 'Month',
      sensors: 'Sensors',
      connections: 'Connections',
      autonomy: 'Battery life (GPS mode)'
    },
    filtersValues: {
      man: 'Men',
      woman: 'Women',
      kid: 'Kids',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      mediumhigh: 'Medium high',
      'carbon-plate': 'Carbon plate',
      light: 'Light weight runners',
      soft: 'soft',
      strong: 'Overweight runners',
      asphalt: 'Road',
      trail: 'Trail',
      track: 'Track',
      training: 'Training',
      competition: 'Racing',
      mixed: 'Mixed terrain',
      dig: 'High arch feet',
      flat: 'Flat feet',
      normal: 'Normal',
      tiptoe: 'Forefoot',
      heel: 'Heel',
      pronator: 'Overpronation',
      neutral: 'Neutral',
      supinator: 'Supination',
      short: 'Short distances',
      maraton: 'Marathon',
      'half-maraton': 'Half-marathon',
      '10k': '10K'
    }
  },
  product_sorting: [
    { value: 'recommended_desc', text: 'Recommended' },
    { value: 'date_desc', text: 'newest' },
    { value: 'price_asc', text: 'price ascending' },
    { value: 'price_desc', text: 'price descending' },
    { value: 'users_desc', text: 'User reviews' },
    { value: 'review_desc', text: 'Expert reviews' },
    { value: 'discount_desc', text: 'Sales' }
  ],
  price_sorting: [
    { value: 'date_desc', text: 'newest' },
    { value: 'price_asc', text: 'from cheap to expensive' },
    { value: 'price_desc', text: 'from expensive to cheap' }
  ],
  author: {
    written: 'written',
    of: 'of',
    reviews: 'reviews',
    articles: 'articles',
    by: 'by',
    Twitter: 'Follow me on Twitter',
    Instagram: 'Follow me on Instagram'
  },
  cookies: {
    text: 'We use our own cookies to keep the identification of registered users and to be able to personalise the services and third party analytical cookies for statistics on the pages consulted, but always in an anonymous way.',
    button: 'Cookie policy',
    accept: 'accept',
    deny: "I don't agree"
  },
  article_list: {
    page_title: 'Running Magazine',
    meta_title: 'Running articles about running shoes, clothes and injuries | Runnea.co.uk',
    meta_description: 'Running articles published by runners and the Runnea team with advice on nutrition, training, running shoes for healthy running.',
    page_title_section: '{seo}',
    meta_title_section: '{seo} articles ',
    meta_description_section: 'Articles and news about {seo} published by runners and the Runnea team with advice on nutrition, training, running shoes for healthy running.',
    page_title_author: '{seo}',
    meta_title_author: 'Articles by {seo} - Running Magazine | Runnea.co.uk',
    meta_description_author: 'Running articles about {seo} published by runners and the Runnea team with advice on nutrition, training, running shoes for healthy running.',
    page_title_text: "'{seo}' Running Magazin",
    meta_title_text: "'{seo}' running articles - running shoes and clothing for healthy running.",
    meta_title_tag_section: '{seo} - running shoes and clothing for healthy running.',
    comparator: 'Comparator',
    nutrition: 'Nutrition',
    running_training: 'Running training',
    sports_injuries: 'Sports injuries',
    running_news: 'News Running',
    podcast: 'Podcast',
    outdoor: 'Outdoor',
    electronics: 'Electronics',
    magazine: 'Magazine'
  },
  article_page: {
    magazine: 'RUNNEA Magazine',
    articles: 'Articles',
    categories: 'Categories',
    share: 'Share',
    follow: 'Follow',
    comments: 'Comments',
    related_articles: 'Related articles',
    leave_comment: 'Comment',
    registered: 'You must be logged in to comment',
    register: 'Log in',
    post: 'Publish',
    notifications: 'Do you want to receive notifications when a user participates in this chat?',
    subscribe: 'Subscribe',
    reportage: 'Story',
    copy_to_clipboard: 'Copy URL to clipboard',
    copied_to_clipboard: 'URL copied to your clipboard',
    posted_on: 'Posted on'
  },
  author_list: {
    page_title: 'Experts in running',
    meta_title: 'Experts in running, nutrition, training and running shoe reviews',
    meta_description: 'Specialists in running, nutrition, training, running shoes to run in a healthy way and help you choose the running shoe that best suits your characteristics and buy it at the best price.',
    page_title_articles: 'Authors of articles about running',
    meta_title_articles: 'Authors of articles about running - Runnea Magazine | Runnea.co.uk',
    meta_description_articles: 'All the authors of articles about running, nutrition, training, running shoes for healthy running on Runnea..',
    page_title_reviews: '{seo} testers',
    meta_title_reviews: '{seo} testers',
    meta_description_reviews: 'Reviews by experts in running, nutrition, training and running shoes to run in a healthy way and help you choose the best trainer or sports watch.',
    authors: 'authors.',
    showing_from: 'Showing from',
    showing_to: 'to'
  },
  author_page: {
    magazine: 'Running articles by',
    articles: 'articles'
  },
  brand_list: {
    brands: 'Brands',
    title: 'Running shoe brands | Runnea.co.uk',
    description: 'All the running shoe brands in our catalogue, to help you choose the best running shoe.',
    marks: 'Running shoe brands'
  },
  store_page: {
    brands: 'Top brands',
    categories: 'Product Categories',
    since: 'Store partnered with Runnea since ',
    reviews: 'Reviews',
    best_sold: 'Best Sellers of'
  },
  calculator: {
    page_title: 'Running pace calculator | Runnea',
    configure_sessions: 'Set up your training sessions and we will calculate the sum of all intervals, the split times after each interval and the split times per kilometre. You can also print out the result or save it for future reference.',
    how_works: 'How it works',
    fill_two: 'Fill in two of the three fields',
    of_each_interval: 'of each interval and we will calculate the third.',
    insert_up: 'Insert up to 20 intervals.',
    added_intervals: 'The added intervals can be sorted or deleted.',
    can_also: 'You can also insert a group of intervals',
    to_which: 'to which you can assign a number of repetitions. Within the group, intervals can be sorted or deleted.',
    save_your: 'Save your training sessions and you will be able to access them whenever you want.',
    to_save: 'To save the sessions, you must be registered.',
    interval: 'Interval',
    distance: 'Distance',
    acronym_meters: '(Mts)',
    rhythm: 'Pace',
    acronym_rhythm: '(Min/Km)',
    time: 'Time',
    actions: 'Actions',
    raise_position: 'Position élevée',
    lower_position: 'Position plus basse',
    remove: 'Éliminer',
    new_interval: 'nouvel intervalle de groupe',
    total: 'Total',
    see_hide: 'Voir/masquer les partielles',
    print: 'Imprimer',
    save: 'Sauvegarder',
    modify: 'Modifier',
    group: 'Groupe',
    delete_all: 'Effacer tout',
    calculate: 'Calculer',
    calculate_training: "Calculez vos temps d'entraînement",
    calculate_marathon: 'Calculez vos temps pendant un marathon',
    calculate_half_marathon: 'Calculez vos temps dans un demi-marathon',
    calculate_ten: 'Calculez vos temps sur 10 km',
    most_search: 'Le plus recherché',
    adv: 'Adv.',
    banner: 'Banner',
    reps: 'Reps.',
    error_missing: "Certains intervalles n'ont pas les valeurs nécessaires",
    error_empty: 'VVider un champ pour pouvoir calculer à nouveau',
    error_not_valid: 'Ces valeurs ne sont pas valides, veuillez les corriger.',
    error_need: "Nous avons besoin d'un minimum de deux valeurs pour faire le calcul"
  },
  calculator_10K: {
    page_title: '10 Km Running Pace Calculator',
    insert_rhythm: 'Enter your pace and we will calculate the time you would do in the 10 km or enter the time you want to do and we will calculate the pace you need to run 10 km.'
  },
  calculator_half_marathon: {
    page_title: 'Half Marathon Running Pace Calculator',
    insert_rhythm: 'Enter your pace and we will calculate the time you would do in a half marathon or enter the time you want to do and we will calculate the pace you need to run a half marathon.'
  },
  calculator_marathon: {
    page_title: 'Marathon Running Pace Calculator',
    insert_rhythm: 'Enter your pace and we will calculate the time you would do in a marathon or enter the time you want to do and we will calculate the pace you need to run a marathon.'
  },
  check_in: {
    checking_user: 'We verify your login'
  },
  comparator_page: {
    page_title: '{seo} comparisons side by side',
    meta_title: '{seo} comparisons side by side',
    meta_description: '{seo} comparison tool to help you choose {seo_the_best} for you.',
    in: 'in',
    add: 'Add',
    best: 'Best',
    price: 'prix',
    money_symbol: '$',
    reviews: 'Reviews',
    queries: 'Questions',
    benefits: 'Benefits',
    characteristics: 'Characteristics'
  },
  comparison_page: {
    page_title: '{seo}',
    meta_title: '{seo} - Comparison',
    meta_description: 'Facts to compare and choose whether to buy {seo}, by viewing the online comparison side by side.',
    in: 'in',
    best: 'Best',
    price: 'price',
    money_symbol: '$',
    opinions: 'Reviews',
    queries: 'Questions',
    benefits: 'Performance',
    characteristics: 'Details',
    soon: 'Soon'
  },
  discount_codes_list: {
    page_title: 'Discount codes: buy your running shoes cheaper in {year}',
    meta_title: 'Discount codes: buy your running shoes cheaper in {year}',
    meta_description: 'All the discount codes available at running stores to make your shopping cheaper.',
    go_to_shop: 'Go to store',
    coupon: 'Discount code',
    copy_code: 'Copy code'
  },
  gallery_page: {
    review: 'Expert Review',
    opinions: 'User review',
    prices: 'Prices',
    queries_recommendations: 'Questions and recommendations',
    photos: 'Photos',
    news: 'News'
  },
  index: {
    covers: 'Covers',
    user_profile: 'User profile',
    sheet: 'Details',
    sheet_of: 'Details of',
    product: 'Product',
    products: 'Products',
    prices: 'Prices',
    race: 'Race',
    marks: 'Brands',
    article: 'Article',
    articles: 'Articles',
    reviews: 'Reviews',
    author: 'Author*',
    authors: 'Authors',
    cupons: 'Discount codes',
    popular_race: 'Fun runs',
    query: 'Question',
    queries: 'Questions',
    galery: 'Gallery',
    comparative: 'Comparison',
    list: 'Lists',
    list_of: 'List of',
    filter_product: '(filtered by product)',
    filter_author: '(filtered by author)',
    filter_two: '(filtered by author and product)',
    conList: 'Articles, Authors, Questions and Galleries',
    calculator: 'Calculator',
    calculators: 'Calculators',
    marathon: 'Marathon',
    half_marathon: 'Half-Marathon',
    ten_k: '10 km',
    link: 'Links',
    redirecting: 'Redirecting to external prices',
    components: 'Components',
    crisis: '(Crisecréative),'
  },
  inquiry_list: {
    meta_title: '{seo} | Running shoe Advisor  Runnea',
    meta_description: '',
    query: 'question',
    inquiries: 'questions',
    inquiries_pl: 'questions',
    inquiries_types_todas: 'all',
    inquiries_types_1: 'My dream shoe',
    showing_from: 'Showing from',
    showing_to: 'to',
    find: 'Search',
    quick_search: 'Quick search',
    category: 'Category',
    ask: 'Ask',
    consultorio: 'Runnea Cabinet',
    make_a: 'Make a',
    required_field: 'Required fields',
    receive_notifications: 'I want to receive notifications when a user participates in ths application',
    consult: 'Ask a question',
    thanks: 'Thank you for submitting your application',
    error_try_again: 'An error occurred while sending the question. Please try again later.',
    write: 'Write your text here',
    search_advanced: 'Advanced search',
    search_keyword: 'Keyword'
  },
  price_list: {
    outlet: 'outlet',
    page_title: '{seo} outlet',
    subpage_title: 'Sales {year} big discounts {seo}',
    meta_title: '{seo} outlet - Buy Cheap Sale',
    meta_description: 'Cheap {seo} outlet, buy online with the best discounts and sales.',
    in_value: 'in {value}',
    of_value: 'of {value}',
    for_value: 'for {value}',
    with_value: 'with {value}',
    only_value: ' {value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'in {filter} {value}',
    of_filter_value: 'of {filter} {value}',
    for_filter_value: 'for {filter} {value}',
    order_by: 'Order by',
    show: 'showing',
    shops: 'shops',
    lowcost: 'Cheap',
    not_found: 'The filters you have selected for',
    not_found_extra: 'did not give us any results.',
    page: 'Page',
    prices_of_product_type: 'Prices of {type}',
    of: 'of'
  },
  product_list: {
    page_title: '{filter} products',
    meta_title: '{filter} products - Online shopping deals and reviews | Runnea',
    search_title: 'Results for ',
    search_product_title: 'Product results for the search ',
    search_article_title: 'Articles containing ',
    not_found: "We couldn't find any products or articles for the search ",
    feedback_text: "Can't find what you're looking for?",
    feedback_action: 'Send us your suggestions',
    feedback_link: '/feedback.html',
    show_more: 'Show more',
    meta_description: 'Range of {filter} running shoes, with reviews and ratings from runners to choose the best trainers at the best price.',
    list: 'List of',
    show: 'Showing',
    of: 'of',
    pag: 'Page',
    list_of: 'List of',
    filters: 'Filters',
    brand: 'Brand',
    gender: 'Gender',
    price: 'Price',
    drop: 'Drop',
    droptie: 'Drop',
    cushioning: 'Cushion',
    constitution: 'Runner weight',
    footprint: 'Strike',
    surface: 'Surface',
    use: 'Use',
    beat: 'Pace',
    foot: 'Foot',
    support: 'Appui',
    distance: 'Distance',
    size: 'Sizes',
    technology: 'Technologie',
    fabric: 'Fabric',
    store: 'Store',
    man: 'Men',
    woman: 'Women',
    kid: 'Kids',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    mediumhigh: 'Mediumhigh',
    'carbon-plate': 'Carbon plate',
    light: 'Ligh weight runner',
    soft: 'Soft',
    strong: 'Strong',
    asphalt: 'Road',
    trail: 'Trail',
    track: 'Track',
    training: 'Training',
    competition: 'Racing',
    mixed: 'Mixed',
    dig: 'High arch feet',
    flat: 'Flat feet',
    normal: 'Normal feet',
    tiptoe: 'Forefoot',
    heel: 'Heel',
    pronator: 'Overpronation',
    neutral: 'Neutral',
    supinator: 'Supination',
    short: 'Short Distances',
    maraton: 'Marathon',
    'half-maraton': 'Half-marathon',
    '10k': '10K',
    in_value: 'in {value}',
    of_value: 'of {value}',
    for_value: 'for {value}',
    with_value: 'with {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'in {filter} {value}',
    of_filter_value: 'of {filter} {value}',
    for_filter_value: 'for {filter} {value}',
    order_by: 'Order by',
    visit: 'Visit our',
    text: 'Text',
    type: 'Type',
    outlet: 'outlet',
    showing_from: '(Showing results from',
    to: 'to',
    new: 'New',
    products: 'Products',
    the: 'the',
    most: 'most',
    sold_out_fem: 'sold',
    sold_out_masc: 'sold',
    about: 'about',
    relevance: 'relevance',
    module_products_opinion_female: '{products} most commented by users',
    module_products_opinion_male: '{products} most commented by users',
    module_reviews: 'Latest {products} reviews',
    top_seller: 'Best selling',
    position: 'Position'
  },
  product_page: {
    analysis: 'Analysis',
    compare: 'Compare',
    comparer: 'Comparator',
    show: 'View',
    show_prices: 'View all prices',
    show_prices_man: "View all men's prices",
    show_prices_woman: "See all women's prices",
    show_more_large: 'More information about this model',
    show_more: 'View more',
    note: 'Reviews of',
    note_fem: 'Reviews of',
    specialist: 'Expert',
    community: 'Community',
    leaf: 'Details',
    review: 'Review',
    review_title: 'Review of {product}',
    reviews: 'Reviews',
    reviews_likes_s: '1 user found this helpful',
    reviews_likes_p: '{number} people found this helpful',
    reviews_like: 'Useful',
    photos: 'Photos',
    opinions: 'User reviews',
    opinions_of: 'User reviews of ',
    inquiries: 'Questions',
    inquiries_of: 'Questions about',
    similars: 'Similar',
    news: 'News',
    news_of: 'News about',
    prices: 'Price',
    from: 'From',
    in: 'in',
    shops: 'Stores',
    collect: 'We collect prices for',
    more: 'more than',
    sellers: 'Sellers',
    save: 'save',
    save_and: 'and to',
    save_to: 'Save up to',
    releases: 'from new releases',
    rest: 'in all other',
    size: 'Sizes',
    size_no: 'You do not know your size',
    query_table: 'View the size guide for',
    username: 'Username',
    user_querys: 'Questions from users',
    doubts: 'Do you have any questions?',
    query_expert: 'Ask our expert',
    compare_with: 'Compare with',
    compare_with_label: 'Compare ',
    with: 'with',
    conclusion: 'Conclusion',
    average_rating: 'Average rating',
    user_average_rating: 'Average user rating',
    user_rating: 'User rating',
    from_users: 'From users',
    lightness: 'Lightness',
    balance: 'Balance',
    response: 'Response',
    flexibility: 'Flexibility',
    damping: 'Cushion',
    grip: 'Grip',
    rate_product: 'Rate this shoe',
    rated: 'of users have evaluated this model of',
    find: 'Find',
    displaying: 'Showing',
    of: 'of',
    querys: 'Questions',
    querys_comments: 'Questions and comments',
    querys_comments_of: 'Questions and comments about',
    show_comments: 'View comments',
    make_query: 'I would like to ask a question',
    news_talk: 'news about this shoe',
    community_talk: 'Trust the Runnea community, our users talk about',
    this: 'these',
    this_masc: 'this',
    features: 'Details -',
    features_of: 'Details of',
    features_and_opinions: 'review and details',
    best_information: 'with information on how to choose the best ',
    buy_at: 'Buy at the best price',
    best_price: 'Best price',
    benefits: 'Advantages',
    photo_gallery: 'Photo galleries',
    original_price: 'Original price',
    gender: 'Gender',
    sizes: 'Sizes',
    choose_size: 'Select your size',
    no_prices_soon: 'In recent days, we have not detected any prices for:',
    no_prices: 'Be the first to receive an alert when prices become available.',
    photo_galery: 'Photo gallerys',
    lower_query: 'questions',
    lower_opinions: 'user opinions',
    min: 'Min:',
    max: 'Máx:',
    anonymous: 'Anonymous',
    no_comments: 'No comments',
    no_queries: 'No questions',
    no_opinions: 'No user reviews',
    be_first_opinion: 'Be the first to leave a review',
    more_compared: 'most compared',
    size_guide: 'Size guide',
    tester: 'Tester',
    testers: 'Our testers tell you their opinion',
    added_product: 'The product has been added to the comparison tool',
    go_to_comparator: 'Go to the comparison tool',
    see_more_prices: 'View more prices',
    more_prices_text: 'See more prices',
    more_prices_of: 'for',
    featured_prices: 'Best offers',
    also_from: 'Other products from',
    characteristics: 'Details of',
    with_information: 'with information on how to choose the best',
    money_acronim: 'USD',
    see: 'View',
    products_of: 'Products of',
    related_to: 'related to this research',
    page: 'Page',
    lower_page: 'page',
    score_text: 'RUNNEA score',
    video: 'Video',
    watch_video: 'Watch video review',
    runnea_score_title: 'What is the RUNNEA SCORE?',
    runnea_score_info: 'It is the score generated based on product features, user interest, opinions and value for money.',
    ranking_text: '<strong>{product}</strong> shoe <b>is ranked {position}</b> in the ranking of the best rated {type}.',
    ranking_view: 'View ranking',
    awards: 'Awards',
    pros: 'Pros',
    cons: 'Cons',
    question: 'Question',
    question_first_letter: 'Q',
    answer: 'Answer',
    answer_first_letter: 'A',
    fan_title: 'Are you a fan of',
    fan_text: 'View all models of:',
    expert: 'Expert'
  },
  product_prices: {
    page_title: '{seo}',
    meta_title: '{seo}{pagina}',
    meta_gender_title: '{seo}{pagina}',
    meta_description: '{seo} price list, run at the best price by choosing trainers with reviews and ratings from runners.',
    best_offer: 'The best deal',
    showing: 'Showing',
    showing_total_prices: 'Showing {total} {seo}',
    users_favourites: 'The favorites of our runners',
    compare_with: 'Compare with',
    find_more_products: 'Find {type} {brand}',
    in_value: 'in {value}',
    of_value: 'of {value}',
    for_value: 'for {value}',
    with_value: 'with {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'in {filter} {value}',
    of_filter_value: 'of {filter} {value}',
    for_filter_value: 'for {filter} {value}',
    show_prices: 'View price',
    alert: 'We will send you a message when they are available',
    add_alert: 'Add alert',
    average_grade: 'User average rating',
    favorites: 'The favorites of our runners',
    fill_position: 'Position ',
    best_note: 'with the best user reviews',
    of: 'of',
    from: 'from',
    to: 'to',
    prices: 'Prices',
    order_by: 'Order by',
    show_filters: 'Show filters',
    prices_of: 'Running shoe prices',
    page: 'Page',
    lower_page: 'page',
    for_selected: 'for selected filters',
    send: 'Send',
    country_from: 'Are you visiting us from {country}?',
    country_change: 'Choose your location'
  },
  race_list: {
    page_title: 'Courses à pied{seo}',
    meta_title: 'Courses à pied{seo}, Calendrier des meilleurs courses',
    meta_description: "Calendrier des courses à pied{seo}, des informations complètes sur les inscriptions, le parcours, le lieu et l'heure de départ.",
    showing: 'Affichant',
    showing_total_races_seo: 'Affichant {total} courses à pied{seo}',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'pour {value}',
    with_value: 'avec {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'pour {filter} {value}',
    race_year: 'Running : Courses à pied en {year}',
    find: 'Recherchez votre course',
    not_found: 'Aucun contenu trouvé pour cette recherche',
    description: "Date, délai d'inscription, parcours et classements de",
    start: 'Départ',
    filters: {
      distance: 'Distance',
      surface: 'Surface',
      province: 'Province',
      country: 'Pays',
      month: 'Mois',
      year: 'année'
    }
  },
  race_page: {
    meta_title: '{seo}',
    meta_description: "Informations utiles pour courir en {seo} avec des détails sur l'inscription, le parcours, le lieu et l'heure de départ, les classements, les coureurs et les habitudes d'entraînement.",
    tagline: 'Date, inscription, parcours et informations générales',
    browser_title: 'Running: Courses à pied dans {year}',
    races_title: 'Courses à pied',
    score_general: 'Géneral',
    score_route: 'Parcours',
    score_organization: 'Organisation',
    score_ambience: 'Environnement',
    score_refreshments: 'Rafraîchissements',
    poster: 'Affiche',
    average_rating: 'Évaluation moyenne',
    from_users: 'Des utilisateurs',
    view_race_web: 'Voir web de la course',
    leaf: 'Fiche',
    inscriptions: 'Inscriptions',
    route: 'Parcours',
    forum: 'Forum',
    verify: 'Verifier',
    feature_date: 'Date',
    feature_place: 'Lieu',
    feature_province: 'Province',
    feature_country: 'Pays',
    feature_surface: 'Surface',
    feature_time: 'Heure de départ',
    feature_participants: 'Participants',
    feature_contact: 'Contact',
    feature_organizer: 'Organisateur',
    feature_distance: 'Distance',
    something: 'Quelque chose',
    heart_rate_monitors: 'Moniteurs de fréquence cardiaque',
    popular_races: 'Courses à pied',
    outlet: 'Outlet',
    description: 'Description',
    will_be: 'aura lieu le',
    in: 'dans',
    anyway_is_important: "Dans tous les cas, il est important que vous confirmiez la date sur le site web de l'Organisateur",
    as_organizers: "Dès que les organisateurs nous auront communiqué le parcours de cette édition ou que la période d'inscription sera ouverte, nous publierons les informations correspondantes, mais si vous avez des questions sur cette course, n'hésitez pas à nous les adresser à",
    of: 'de',
    or_consult: "ou consultez le site web de l'organisation de la course.",
    inscription: "Date d'inscriptions à la",
    if_you_want: "Si vous voulez savoir quand s'ouvre la période d'inscription de cette édition de la",
    be_aware: "restez à l'écoute de notre site web et de notre forum de la",
    because: 'parce que nous informerons de manière ponctuelle.',
    unfold_info: 'Au fur et à mesure que se dévoilent les détails du parcours de cette édition de la',
    will_inform: 'nous vous tiendrons informé.',
    questions_doubts: 'Si vous avez des questions ou des doutes sur la course, vous pouvez envoyer vos questions à notre forum de la',
    fill_form: 'Remplissez ce formulaire afin que nous puissions le valider',
    organizer: "Êtes-vous l'organisateur de la course?",
    verify_race: 'Vérifier la course'
  },
  redirecting: {
    redirecting: 'We will redirect you to the official website',
    coupon_advice: "To get the price you clicked on, don't forget to use the discount code!",
    accept_button: 'Got it'
  },
  review_list: {
    page_title: '{product} reviews',
    meta_title: '{product} reviews',
    meta_description: '{product} reviews that help in choosing the best product.',
    reviews: 'reviews | review | reviews',
    showing_from: 'Showing from',
    showing_to: 'to',
    by: 'by'
  },
  opinion_list: {
    page_title: '{seo} user reviews',
    meta_title: '{seo} user reviews',
    meta_description: '{seo} user reviews | Runnea, help in choosing the best running shoe.',
    opinions: 'user reviews | use review | user reviews',
    showing_from: 'Showing from',
    showing_to: 'to'
  },
  login: {
    session: 'Log in',
    welcome: 'Welcome to Runnea',
    forget: 'I have forgotten my password',
    send: 'Send',
    back: 'Back',
    genericerror: 'Connection error',
    closeSession: 'Log out',
    no_account: "I don't have an account, I want to register",
    logout: 'Log out',
    login: 'Log in',
    register: 'Register',
    incorrect_email: 'Incorrect e-mail address',
    incorrect_password: 'Invalid password',
    remmember_password: 'Remember your password'
  },
  reset_password: {
    new: 'New password',
    repeat: 'Repeat password',
    send: 'Send',
    reset_password: 'Enter your new password to log in',
    change: 'Change password',
    different_passwords: 'Passwords are different',
    description: 'The password must contain 8 characters, be alphanumerical and contain capital letters',
    successfully_update: 'You have changed your password. You can now go to the page to log in',
    unknown_error: 'Unknown error',
    error: 'Error'
  },
  register: {
    username: 'User name',
    email: 'E-mail',
    name: 'First name',
    sex: 'Gender',
    man: 'Man',
    woman: 'Woman',
    country: 'Country',
    postalCode: 'Postcode',
    brand: 'Do you have a favorite brand?',
    size: 'Size',
    send: 'Send',
    facebook: 'Log in with Facebook',
    google: 'Log in with Google',
    password: 'Password',
    repeat_password: 'Repeat password',
    Emailnotcorrect: 'The e-mail is not correct',
    Passwordsdontmatch: 'Passwords do not match',
    LowercaseLetterRequired: 'A lower case letter is required.',
    UppercaseLetterRequired: 'A capital letter is required.',
    '8Characters': 'Minimum 8 characters.',
    OneNumber: 'A number is required.',
    PasswordIncorrect: 'The password is not correct, check that it contains lower case, upper case, more than 8 characters and some numbers.',
    registrate: 'Sign up',
    successfully_registered: 'Successfully registered',
    not_exist_email: 'E-mail does not exist',
    remember_ok: 'We have sent you an e-mail to the address {email}. Check your inbox to retrieve your password.',
    user_not_found: 'Non-existing user',
    error_recovering: 'Data recovery error',
    email_exists: 'The e-mail already exists',
    email_not_found: 'The e-mail was not found',
    missing_fields: 'Missing fields to be completed',
    incorrect_format: 'Incorrect format'
  },
  verify: {
    name_surname: 'Name and surname',
    email: 'E-mail',
    tlf: 'Contact phone number',
    days: 'Days of the week when we can contact you',
    hour: 'Hours of availability',
    send: 'Send',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursady',
    friday: 'Friday',
    extra: 'Additional information',
    emailnotcorrect: 'The email is not correct',
    verified: 'Thank you for filling in the form, we will contact you to confirm the information.',
    not_verified: 'Lo sentimos, algo ha ido mal, intentalo más tarde.',
    missing_fields: 'Faltan campos por completar',
    accept: 'Acepta la',
    acceptPol: 'politica de privacidad',
    form_first: '¿Eres el organizador del evento ',
    form_first_after_bold: '?',
    form_second: 'Verifica los datos de la carrera para dar más visibilidad al evento de forma gratuita.',
    form_third: 'Rellena el siguiente formulario y nos pondremos en contacto contigo para finalizar el proceso de verificación. '
  },
  registration: {
    already_account: 'I already have an account, I want to log in',
    continue: 'Continue'
  },
  talk_page: {
    consultant: 'Forum'
  },
  user_page: {
    modify: 'Edit photo',
    save: 'Save changes',
    select: 'Select'
  },
  not_found_page: {
    pageNotFound: 'Page not found',
    pageNotFoundMsg: "We're sorry, the page you're looking for was not found.\nYou can go back or return to the home page.",
    goHome: 'Go to the Home page'
  },
  article_finder: {
    find_here: 'Search a magazine article'
  },
  AuthorLeaf: {
    has_written: 'has written',
    num_reviews_of: '0 reviews about | 1 review about | {count} reviews about',
    of: 'of',
    reviews: 'reviews',
    articles: 'articles'
  },
  AuthorMiniLeaf: {
    by: 'by'
  },
  BrandCard: {
    size_chart: 'Size chart',
    go_to_size_chart: 'Go to size chart'
  },
  BrandsProduct: {
    also: 'Other products by',
    from: 'From',
    money: '$',
    find: 'Find your running shoes'
  },
  DiscountCodeCard: {
    see: 'View discount',
    share: 'Share',
    go_to_store: 'Go to store',
    copied: 'Copied',
    special_cupon: 'Special code',
    date_up_to: 'Until',
    read_more: 'Read more'
  },
  InquiryTitleCard: {
    other_queries: 'Other questions about running'
  },
  ModuleCarousel: {
    new: 'New release!',
    upper_new: 'New releases',
    discover: 'Discover',
    exclusive: 'Exclusive',
    text_1: 'Virtual races arrive at Runnea Academy ',
    text_2: 'Runnea presents the list of the best virtual races in '
  },
  ModuleChats: {
    title: 'Questions and comments about {content}',
    showing: 'Questions from {start} to {end} ',
    show_all: 'View {total} user questions',
    no_contents: 'No questions or comments'
  },
  ModuleComments: {
    title: 'Comments of {content}',
    showing: 'Comments from {start} to {end} ',
    show_all: 'View {total} user questions',
    no_contents: 'No comments'
  },
  ModuleForm: {
    title: 'Contact',
    field: 'Field',
    is_required: 'is required',
    query_received: 'We have received your request, and will be in contact in under 48 huors. Thank you for contacting us.',
    error_submitting: 'Error submitting form',
    go_to_form: 'Go to form',
    confirmButton: 'OK'
  },
  ModuleMenu: {
    running_shoes: 'Running shoes',
    trekking_shoes: 'Hiking shoes ',
    trail_shoes: 'Trail running shoes',
    treadmill: 'Treadmills',
    pulsometer: 'Sports watches',
    running_training: 'Running training',
    menu: 'Menu',
    login: 'Log in',
    logout: 'Log out',
    account: 'My account',
    title_ra: 'Online personal coach for running and trail Running',
    claim: 'We help you run better!',
    categories: 'All categories'
  },
  ModulePricesBargain: {
    new: 'New bargain',
    discount: 'off',
    go: 'Go to the bargain',
    click: '1 click',
    clicks: '{clicks} clicks'
  },
  newChatButton: {
    description: 'Si tienes dudas en un tema específico, puedes elegir a un experto para que te ayude. O si lo prefieres, puedes lanzar una pregunta a la comunidad y que te responda cualquiera de nuestros expertos u otros usuarios.',
    options: 'Elige una opción',
    your_inquiry: 'Tu consulta',
    your_inquiry_for: 'Tu consulta para:',
    to_do: 'Make',
    do: 'Done',
    quest: 'Choose a topic and ask your question.',
    inquiry: 'question | questions',
    inquiries: 'no questions | one question | questions',
    select_category: 'Select a category',
    inquiry_description: 'Description of the question',
    write_your_text: 'Write your text here',
    required_field: 'Required fields',
    receive_notifications: 'I want to receive notifications when a user participates in this question',
    not_logged_in: 'You are not logged in',
    please_login_msg: 'You are not logged in. Please login',
    thanks: 'Thank you for submitting your comment',
    try_again: 'An error occurred while sending the request. Please try again later.',
    mentioned_shoes: 'Mentioned running shoes',
    new_inquiry: 'New question',
    new_inquiry_text: 'If you have any doubts about running gear, our experts can help you.',
    modal: {
      title: 'Pregunta a un experto',
      text: 'Si necesitas aclarar cualquier duda sobre material de running escríbenos y nuestro equipo de expertos te ayudará en todo lo que pueda.'
    }
  },
  newCommentButton: {
    reply: 'Reply',
    inquiry: 'question | questions',
    write_your_text: 'Write your text here',
    required_field: 'Required fields',
    receive_notifications: 'I want to receive notifications when a user participates in this question',
    not_logged_in: 'You are not logged in',
    please_login_msg: 'You are not logged in. Please login',
    thanks: 'Thank you for participating in the application',
    try_again: 'An error occurred while sending the comment. Please try again later.'
  },
  newOpinionButton: {
    new: 'Give us your opinion',
    not_logged_in: 'You are not logged in',
    please_login_msg: 'You are not logged in. Please login',
    performance: 'Services',
    comment: 'Comment',
    opinion: 'Send your review',
    score: 'Score',
    write: 'Write your review',
    thanks: "Thanks from the entire RUNNEA team and the entire RUNNEA running community. Your review will help us all to choose better. Don't hesitate to send us feedback on other models.",
    next: 'In a few hours you will see your review published on the web.',
    try_again: 'An error occurred while sending your review. Please try again later.'
  },
  newCommentModule: {
    leave_comment: 'Leave your comment',
    registered: 'You must be registered to comment',
    register: 'Sign up',
    placeholder: 'Write your review',
    required_field: 'Required field',
    post: 'Publish',
    thanks_msg: 'Comment sent. Thank you for participating.',
    error_msg: 'An error occurred while sending your comment. Please try again later.',
    rgpd: ''
  },
  NewsLettersCard: {
    message: 'Find out all the news about the world of running and offers on sports equipment.',
    highlight: 'Are you going to miss it?',
    remember: "Don't forget to accept the terms and conditions",
    successful: 'We have just registered you.',
    error: 'An error has occurred. Please try again later',
    error_try: 'An error occurred. Please try again later.',
    required: 'Required fields',
    thanks: 'Thanks'
  },
  ProductCardPrincipal: {
    see: 'View details',
    see_description: 'See all features'
  },
  ProductHeaderButtons: {
    alert_received: 'Very well, your price alert has been set up, if we detect a price matching your criteria, we will send you an alert.',
    error_submitting: 'The alert could not be created. Please try again.',
    compare: 'Compare',
    alert: ' I want to be informed if the price goes down',
    available: "Inform me when it's available",
    disable_alert: 'Alert',
    disable_price_alert: 'Deactivate the price alert',
    close: 'Close',
    our_comparator: 'Our price comparison tool finds millions of prices every day. Set up your price alerts and if we find a price that matches your criteria, we will send you an alert.',
    model: 'Price alert for ',
    sure: "Click on the 'Confirm' button to deactivate this price alert",
    confirm: 'Confirm',
    alert_deleted: 'You have deleted the price alert you had set up on the {marca} {producto} model.',
    error_deleting_alert: 'We were unable to remove the price alert you had set up on the {marca} {producto} model. Please contact us so that we can remove it.'
  },
  ProductFeedback: {
    chat_1: 'Hi{user}',
    chat_2: 'What can you tell us about {product}?',
    state_wish: 'I love them',
    state_have: 'I have them',
    state_dislike: "I don't like them",
    votes: 'votes',
    response_wait: 'Updating your participation...',
    response_ok: 'Thank you for participating!',
    response_ko: 'Oops, something has failed. Try again later.'
  },
  ContentPremium: {
    title: 'Sign in with your account to view content',
    description: 'Create your FREE account and enjoy all exclusive content',
    advantages_1: 'Unlimited access to restricted articles',
    advantages_2: 'Newsletters with personalized content',
    advantages_3: 'Alerts and sweepstakes'
  },
  SliderCardFinder: {
    find: 'Search'
  },
  UserPasswordForm: {
    new_password: 'Enter your Runnea e-mail and we will send you a new password.'
  },
  UserRegisterForm: {
    continue_with: 'Continue with',
    or: 'or',
    start: '¡Fill in a few details and start running!',
    required: 'Mandatory field',
    access_data: 'Your login details',
    incorrect_user: 'Incorrect user',
    remmember_password: 'Remember password',
    congratulations: 'Congratulations!',
    welcome: 'Welcome to the Runnea community',
    complete: 'Enter the following information and start!',
    no_account: "I don't have an account",
    name: 'Name',
    surname: 'Surname',
    user: 'User',
    email: 'Email',
    password: 'Password',
    tread_type: 'Foot',
    dont_know: "Don't know",
    pronator: 'Pronation',
    neutral: 'Neutral',
    supinator: 'Supination',
    commercial_communications: '',
    legal: 'Accept <a href="https://www.runnea.co.uk/legal-advice.html" target="_blank" rel="noopener noreferrer" class="legal_notice">privacy policy</a>. By making your account you are accepting the Runnea terms and conditions.',
    rgpd: ''
  },
  UserAuthForm: {
    wrong_password: 'Incorrect user or password',
    no_email: "This e-mail doesn't exist"
  },
  influencer_card: {
    reviews: 'Reviews',
    last_review: 'Last review',
    best_review: 'Best review',
    show_price: 'View price'
  },
  inquiry_card: {
    comments: 'Comments',
    no_comments: 'No comments'
  },
  inquiry_finder: {
    find_text: 'Filter by text',
    find_product: 'Filter by product'
  },
  list_filters: {
    filters: 'Filters',
    filter: 'Filter',
    return: 'Back',
    delete: 'Delete',
    sizes: 'Sizes',
    show: 'Show',
    results: 'Results',
    example: 'e.g. Nike'
  },
  list_pagination: {
    before: 'Previous',
    next: 'Next',
    first_page: 'First page',
    last_page: 'Last page'
  },
  price_card: {
    shipping_costs: 'Shipping cost',
    from_price: 'from $ {price}',
    buy: 'Buy',
    go_to_store_w_price: 'Go to <i>{store}</i> store and buy at $ {price} ',
    free_shipping: 'Free shipping',
    show_offer: 'View offer',
    till: 'till',
    coupon: 'With code',
    coupon_no: 'No Code Needed',
    man: 'Man',
    woman: 'Woman',
    kid: 'Kids',
    unisex: 'Man - Woman'
  },
  product_card_price_opinion: {
    let_opinion: 'write your review',
    opinions: 'User reviews',
    questions: 'Questions'
  },
  product_finder: {
    find_here: 'Search'
  },
  brand_finder: {
    find_here: 'Filter by brand'
  },
  race_finder: {
    find_here: 'Search an article',
    search: 'Search'
  },
  module_finder: {
    title: 'Running Shoes',
    text1: 'Find the best price among more than 20.000 running shoes',
    text2: 'We compare more than 2,000,000 running shoes from leading brands',
    placeholder: 'Running Shoes',
    need_help: 'Need help with your choice?',
    just_ask: 'Ask our specialists',
    where_start: "Don't know where to start?",
    use_shoe_finder: 'Try our recommender!',
    search_by_brand: 'Search by brand'
  },
  footer: {
    about: 'About us',
    newsletter: 'Newsletter',
    publicity: 'Advertising',
    product_test: 'Product test',
    shops: 'Your store and your products',
    products: 'Products',
    contact: 'Contact',
    legal: 'Legal advice',
    privacity: 'Privacy policy',
    cookies: 'Cookie policy',
    work: 'Work with us',
    who: 'Who we are',
    legal_information: 'Legal advice',
    license: '',
    other_webs: 'Other Runnea websites',
    copyright: 'RUNNEA Copyright',
    follow: 'Follow us',
    union: '',
    union_deep: '',
    union_mes: '',
    union_mode: ''
  },
  product_header: {
    specialist: 'Expert reviews',
    community: 'User reviews',
    leaf: 'Details',
    review: 'Reviews',
    opinions: 'User reviews',
    inquiries: 'Questions and recommendations'
  },
  user_auth_form: {
    facebook: 'Login with Facebook',
    google: 'Login with Google'
  },
  youtube: {
    suscribe: 'Subscribe to our channel',
    review: 'Video review'
  },
  app_finder: {
    example: 'Brand or model...',
    popular: {
      title: 'Discover',
      list: {
        1: {
          title: "Women's running shoes",
          link: '/running-shoes/models/all/for-womens/'
        },
        2: {
          title: "Men's running shoes",
          link: '/running-shoes/models/all/for-mens/'
        },
        3: {
          title: 'Trail running shoes',
          link: '/running-shoes/models/all/surface-trail/'
        },
        4: {
          title: 'Hiking shoes',
          link: '/hiking-shoes/models/all/'
        },
        5: {
          title: 'Sports watches',
          link: '/sports-watches/models/all/'
        }
      }
    },
    most_searched: {
      title: 'Most searched models',
      list: {
        1: {
          title: 'Nike Pegasus 40',
          link: '/running-shoes/nike/pegasus-40/1018056/',
          img: 'https://static.runnea.co.uk/images/202305/nike-pegasus-40-running-shoes-400x400x90xX.jpg?1'
        },
        2: {
          title: 'ASICS Gel Nimbus 25',
          link: '/running-shoes/asics/gel-nimbus-25/1006079//',
          img: 'https://static.runnea.co.uk/images/202212/asics-gel-nimbus-25-running-shoes-400x400x90xX.jpg?2'
        },
        3: {
          title: 'Adidas Ultraboost Light 23',
          link: '/running-shoes/adidas/ultraboost-23/1010328/',
          img: 'https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1'
        },
        4: {
          title: 'New Balance FuelCell SC Elite v3',
          link: '/running-shoes/new-balance/fuelcell-sc-elite-v3/1010640/',
          img: 'https://static.runnea.co.uk/images/202302/new-balance-fuelcell-sc-elite-v3-running-shoes-400x400x90xX.jpg?3'
        },
        5: {
          title: 'Garmin Fenix 7',
          link: '/sports-watches/garmin/fenix-7/1005866/',
          img: 'https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-200x200x80xX.jpg?1'
        }
      }
    }
  },
  consent_cookie: {
    consent_modal: {
      title: 'We use cookies to give you the best possible experience',
      description: 'We use our own cookies to ensure basic operation and provide the best experience and third party cookies to obtain statistical data about your browsing and advertise personalised products. For more information, you can consult our <a href="/cookie-policy.html">cookie policy</a>, our <a href="/legal-advice.html">legal advice</a> and our <a href="/privacy-policy.html">privacy policy</a>',
      primary_btn: 'Accept all',
      secondary_btn_1: 'Manage cookies',
      secondary_btn_2: 'Reject all'
    },
    settings_modal: {
      title: 'Manage cookies',
      save_settings_btn: 'Save the configuration',
      accept_all_btn: 'Accept all',
      reject_all_btn: 'Reject all',
      close_btn_label: 'Close',
      cookie_table_headers: {
        col1: 'Name',
        col2: 'Domain',
        col3: 'Time',
        col4: 'Description'
      },
      blocks_1: {
        title: 'Use of cookies',
        description: 'We use our own cookies to ensure basic operation and provide the best experience and third party cookies to obtain statistical data about your browsing and advertise personalised products. For more information, you can consult our <a href="/cookie-policy.html">cookie policy</a>, our <a href="/legal-advice.html">legal advice</a> and our <a href="/privacy-policy.html">privacy policy</a>.'
      },
      blocks_2: {
        title: 'Strictly necessary cookies',
        description: 'These cookies are essential for the proper functioning of the website. Without these cookies, the website would not function properly.'
      },
      blocks_3: {
        title: 'Functional cookies',
        description: 'These cookies are important for the proper functioning of the website. Without these cookies, we cannot offer you the best experience.'
      },
      blocks_4: {
        title: 'Analysis and performance cookies',
        description: 'These cookies allow us to anonymously measure the use and performance of the website.'
      },
      blocks_5: {
        title: 'Advertising and marketing cookies',
        description: 'These cookies allow us to advertise personalised products to the user.'
      },
      cookies: {
        hash: {
          expiration: 'Session',
          description: 'Stores the information of the page from which the login and registration process is performed when using the connection with social networks (Google or Facebook)'
        },
        auth_strategy: {
          expiration: '1 month',
          description: "Stores information from the user's chosen login system (Local, Google or Facebook)"
        },
        cc_runnea: {
          expiration: '1 year',
          description: "Saves information about the user's cookie preferences"
        },
        brander: {
          expiration: 'Between 1 day and 1 year',
          description: 'Saves information about the display of information layers so that they are not displayed again'
        },
        ga: {
          expiration: '2 years',
          description: 'These cookies are used by Google Analytics to distinguish unique users by assigning them a randomly generated number'
        },
        gid: {
          expiration: '2 years',
          description: 'These cookies are used by Google Analytics to generate a unique user ID to count how many times a user visits the site, and the date of the first and last visit'
        },
        fbp: {
          expiration: '3 months',
          description: 'Used by Facebook to provide a range of advertising products, such as real-time offers from external advertisers.'
        },
        gads: {
          expiration: '13 months',
          description: "Associated with Google's DoubleClick for Publishers service, its purpose is to measure interactions with ads in order to prevent them from being shown repeatedly."
        }
      }
    }
  },
  recommender: {
    seo: {
      title: 'Shoe Finder: help wizard to help you choose which shoe to buy | RUNNEA',
      description: 'Find the running shoe, trail, trekking or sneaker that best fits each profile, step by step.'
    },
    tabs: {
      start: {
        text: 'Hi runner! Looking for a new pair of shoes? We help you find the best fit for you and your budget in a few simple steps.',
        button: 'Start',
        results: 'View results',
        autor: 'Gorka Cabañas',
        specialist: 'Running shoes specialist'
      },
      purpose: {
        title: "I'm looking for shoes for...",
        text: 'Running on asphalt is not the same as running in the mountains. Nor is a walking shoe the same as a fashion shoe. Tell us what kind of shoes you are looking for.',
        options: {
          running: 'Asphalt',
          trail: 'Trail'
        }
      },
      gender: {
        title: 'What kind of shoes do you need?',
        text: 'When looking for a shoes model we have to base our search on two main options.',
        options: {
          man: 'Man',
          woman: 'Woman'
        }
      },
      size: {
        title: 'Tell us your size',
        text: "RUNNEA advice: RUNNEA's team of podiatrists recommends choosing shoes for sports, half a size more than the one you usually use.",
        options: {
          default: 'Choose size'
        }
      },
      footprint: {
        title: 'Type of footprint',
        text: "If you don't know how you walk, we recommend that you have a footprint analysis. It will help you avoid injuries and run more safely.",
        options: {
          pronator: 'Overpronation',
          neutral: 'Neutral',
          supinator: 'Supination',
          none: "Don't know"
        }
      },
      constitution: {
        title: 'I am...',
        text: 'Our constitution has a determining influence on the choice of one shoe or another. The heavier we are, the more cushioning we need.',
        options: {
          light: 'Light',
          medium: 'Medium',
          strong: 'Overweight',
          none: "Don't know"
        }
      },
      cushioning: {
        title: 'What type of cushioning are you looking for?',
        text: 'Do you prefer a firmer cushioning but one that will help you improve your times or are you looking for comfort and maximum cushioning while sacrificing that reactivity?',
        options: {
          soft: 'Fast & Reactive',
          neutral: 'Intermediate',
          high: 'Maximum cushioning',
          none: "Don't know"
        }
      },
      foot: {
        title: 'What is your foot type?',
        text: 'Knowing your foot type helps us to select a shoe that best suits your running style.',
        options: {
          normal: 'Normal feet',
          dig: 'High arch feet',
          flat: 'Flat feet',
          none: "Don't know"
        }
      },
      use: {
        title: 'What use will you give to the shoes?',
        text: 'Ideally, you should have one shoe for racing and one for training, but you always have the option of choosing a mixed shoe that you can use for both training and racing.',
        options: {
          training: 'Training',
          competition: 'Racing',
          mixed: 'Mixed terrain'
        }
      },
      distance: {
        title: 'What distances are you going to train?',
        text: "No one here is going to judge how many miles you run, but while we're at it, are you more of a short or long distance runner?",
        options: {
          short: 'Short distance',
          '10K': '10K',
          'half-maraton': 'Half marathon',
          maraton: 'Marathon',
          'ultra-trail': 'Ultra trail'
        }
      },
      beat: {
        title: 'At what pace do you usually run?',
        text: 'Are you one of those who do not look at the clock when you go out to run or on the contrary, you train every day to improve your time?',
        options: {
          high: 'Under 4:30 Min/Km',
          medium: 'Between 4:30 and 5:30 Min/Km',
          low: 'Over 5:30 Min/Km',
          none: "Don't know"
        }
      },
      brand: {
        title: 'Do you have a favorite brand?',
        text: 'Do you have a closed relationship with your favorite brand or are you open to new shoes?',
        options: {
          default: 'Choose brand',
          none: 'Surprise me!'
        }
      },
      price: {
        title: 'How much are you planning to spend?',
        text: 'Budget is important but at RUNNEA we want to help you find your ideal shoe at the best price.',
        options: {
          low: 'Under 60€',
          medium: 'Under 100€',
          high: 'Over 100€',
          none: 'No budget'
        }
      },
      result: {
        texts: {
          loading: 'We are looking for the shoes that best fit the characteristics that you have just configured...',
          contents: 'The best shoes for you are...',
          void: 'We have not found shoes with all the features you have given us. Try our running shoes search engine:',
          more_results: 'You can see more results here'
        },
        product: {
          index: 'See features',
          prices: 'View all prices'
        },
        buttons: {
          contents: 'See other options',
          void: 'See other options'
        }
      }
    }
  },
  runningMusic: {
    imgCalienta: 'https://static.runnea.com/images/202309/calienta.jpg?1',
    imgInfoIntencidad: 'https://static.runnea.com/images/202309/info-intensidad.png?1',
    imgIntencidad: 'https://static.runnea.com/images/202309/intensidad.png?1',
    imgPop: 'https://static.runnea.com/images/202309/pop.png?1',
    imgRock: 'https://static.runnea.com/images/202309/rock.png?1',
    imgSession01: 'https://static.runnea.com/images/202309/sesion-01.png?1',
    imgSession02: 'https://static.runnea.com/images/202309/sesion-02.png?1',
    imgSession03: 'https://static.runnea.com/images/202309/sesion-03.png?1',
    imgTechno: 'https://static.runnea.com/images/202309/techno.png?1',
    imgUrban: 'https://static.runnea.com/images/202309/urban.png?1'
  },
  recomend: {
    title: 'Recomendador de Zapatillas',
    subTitle: 'Respuesta inmediata',
    descriptions: 'Te guía con pasos sencillos para escoger tu zapatilla'
  }
}
