//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { customPageView } from '~/lib/gtm'

export default {
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  layout: 'redirecting',
  data () {
    const head = {
      title: 'Runnea - Redirect'
    }
    return {
      url: '',
      runnealogo: { width: 143, height: 25, alt: 'Runnea logo', class: 'runnea-logo', src: require('@/assets/img/logo-header-runnea.svg') },
      prevRoute: null,
      head,
      lsTrackInfo: null,
      tracking: '',
      timer: 0
    }
  },
  head () {
    return this.head
  },
  async beforeMount () {
    this.lsTrackInfo = await JSON.parse(localStorage.getItem('trackInfo'))
    localStorage.removeItem('trackInfo')
  },
  async mounted () {
    const ccCookie = this.$cookiz.get('cc_runnea')
    if (ccCookie && ccCookie.categories.includes('analytics')) {
      this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAnalytics' })
    }
    customPageView.apply(this, [this.head.title], false)

    const gtOrigen = this.$cookiz.get('gt_origen')

    const apiBaseUrl = this.$config.api + '/app/v1/prices/tracking'

    let apiQryStr = `_p=${this.$config.id}&cod=` + this.$route.query.cod
    apiQryStr += (this.$route.query.pos) ? '&pos=' + encodeURIComponent(this.$route.query.pos) : ''
    apiQryStr += (this.$route.query.org) ? '&org=' + encodeURIComponent(this.$route.query.org) : ''
    apiQryStr += (this.$route.query.at) ? '&at=' + encodeURIComponent(this.$route.query.at) : ''
    apiQryStr += '&url_origen=' + encodeURIComponent(this.prevRoute.path)
    apiQryStr += (gtOrigen) ? '&source=' + gtOrigen : ''

    // User Agent
    const d = this.$device

    let so = 'unknown'
    if (d.isAndroid) { so = 'android' } else if (d.isIos) { so = 'ios' } else if (d.isWindows) { so = 'windows' } else if (d.isMacOS) { so = 'macos' }
    let browser = 'unknown'
    if (d.isChrome) { browser = 'chrome' } else if (d.isSafari) { browser = 'safari' } else if (d.isEdge) { browser = 'edge' } else if (d.isFirefox) { browser = 'firefox' } else if (d.isCrawler) { browser = 'crawler' }
    let device = 'unknown'
    if (d.isMobile) { device = 'mobile' } else if (d.isDesktop) { device = 'desktop' } else if (d.isTablet) { device = 'tablet' }

    // Params Click
    apiQryStr += '&prod_categoria=' + (this.lsTrackInfo?.type || 'unknown')
    apiQryStr += '&prod_nombre=' + (this.lsTrackInfo?.product || 'unknown')
    apiQryStr += '&prod_marca=' + (this.lsTrackInfo?.brand || 'unknown')
    apiQryStr += '&prod_tienda=' + (localStorage.getItem('click_origin') || 'directo')
    apiQryStr += '&modulo_tipo=' + encodeURIComponent(this.$route.query.org)
    apiQryStr += '&card_posicion=' + encodeURIComponent(this.$route.query.pos)
    apiQryStr += '&card_cupon=' + (localStorage.getItem('trackEvent') || 'unknown')
    apiQryStr += `&so=${so}&browser=${browser}&device=${device}`
    apiQryStr += `&featured=${this.lsTrackInfo?.featured || '0'}`
    apiQryStr += `&url_raiz=${this.lsTrackInfo?.url ? this.$config.domain + this.lsTrackInfo?.url : 'unknown'}`

    this.tracking = await this.$axios.get(apiBaseUrl + '?' + apiQryStr, { withCredentials: true })

    localStorage.removeItem('trackEvent')

    this.timer = setTimeout(() => this.activeRedirect(), this.lsTrackInfo?.coupon ? 4000 : 1500)
  },
  methods: {
    activeRedirect (from = 'auto') {
      if (from === 'boton') {
        clearTimeout(this.timer)
        window.location.replace(this.tracking.data.url)
      } else {
        window.location.replace(this.tracking.data.url)
      }
    }
  }
}
