//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { filtersUrlIsIndexable } from '~/lib/seo'
export default {
  mixins: [global],
  props: {
    type: {
      type: String,
      default: ''
    },
    filters: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    config: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    location: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: ''
    },
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    total: {
      type: Number,
      default: 0
    },
    showSmallBtn: {
      type: Boolean,
      default: false
    },
    showFilterBtn: {
      type: Boolean,
      default: true
    },
    textSearch: {
      type: Object,
      default: () => {
        return { active: false, text: '' }
      }
    }
  },
  data () {
    return {
      filterActive: false,
      filterTypeFilters: true, // true: filtros, false: tallas
      iconChevronDown: require('~/assets/img/icons/icon-chevron-down-grey.svg'),
      gtmData: {}
    }
  },
  computed: {
    filtersView () {
      let filtersFiltered = this.filters.view.filter(
        f => (f.id === 'size' && !this.filterTypeFilters) || this.filterTypeFilters
      )
      if (
        this.type === 'product_prices' &&
        this.config.metas?.product_prices?.filters
      ) {
        filtersFiltered = filtersFiltered.filter((f) => {
          let filterExists = false
          Object.values(this.config.metas.product_prices.filters).forEach(
            (filters) => {
              filterExists = filterExists || filters.includes(f.id)
            }
          )
          return filterExists
        })
      }

      const originalFiltersActive = {}
      if (this.filters.active) {
        this.filters.view.forEach((filter) => {
          if (this.filters.active[filter.id]) {
            const valuesActive = []
            filter.values.forEach((value) => {
              if (this.filters.active[filter.id].includes(value.id)) {
                valuesActive.push(value.id)
              }
            })

            if (valuesActive.length > 0) {
              originalFiltersActive[filter.id] = valuesActive
            }
          }
        })
      }

      filtersFiltered.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value
            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.filters.url,
              this.config,
              this.type,
              this.location,
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(originalFiltersActive))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.config, this.type)

            return valueMapped
          })
        }

        return filterMapped
      })

      return filtersFiltered
    },
    filtersActive () {
      let filtersViewActive = [...this.filters.view]
      filtersViewActive = filtersViewActive.filter(
        f => (f.active === 1)
      )
      const filtersActive = {}
      filtersViewActive = filtersViewActive.map(
        filter => {
          const newValues = filter.values.filter(
            value => (value.active === 1)
          )
          return { ...filter, values: newValues }
        })
      filtersViewActive.forEach(
        filter => {
          filtersActive[filter.id] = filter.values.length
        }
      )
      return filtersActive
    }
  },
  mounted () {
    document.body.classList.add('with-bottom-bar')
  },
  beforeMount () {
    this.gtmData = {
      location: this.location
      // ...features,
    }
  },
  beforeDestroy () {
    document.body.classList.remove('with-bottom-bar')
  }
}
