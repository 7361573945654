//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import project from '~/static/config/project.json'

export default {
  name: 'DefaultAmp',
  data () {
    const ga4Config = project.plugins?.ga4?.id
      ? {
          linkers: {
            enabled: true,
            destinationDomains: ['*.' + project.domains.www.path],
            _gl: {
              ids: {},
              proxyOnly: false
            }
          },
          vars: {
            GA4_MEASUREMENT_ID: 'G-' + project.plugins.ga4.id,
            GA4_ENDPOINT_HOSTNAME: 'www.google-analytics.com',
            DEFAULT_PAGEVIEW_ENABLED: true,
            GOOGLE_CONSENT_ENABLED: false,
            WEBVITALS_TRACKING: false,
            PERFORMANCE_TIMING_TRACKING: false,
            SEND_DOUBLECLICK_BEACON: false
          }
        }
      : false
    // eslint-disable-next-line no-template-curly-in-string
    ga4Config.linkers._gl.ids['_ga_' + project.plugins.ga4.id] = '${ga4SessionCookie}'
    return {
      ga4Config,
      staticDomain: project.domains.static.domain
    }
  }
}

