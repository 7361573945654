//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
import { openAuthRequired } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    url: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    nofollow: {
      type: Boolean,
      default: false
    },
    track: {
      type: [Object, String],
      default: null
    },
    ga: {
      type: Object,
      default: null
    },
    obfuscated: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const absolute = this.url.match(/^https?:\/\//) && true
    const urlRegex = new RegExp(`^https?://${projectData.domains.www.domain}`)
    const outer = absolute && !this.url.match(urlRegex)

    const relContent = []
    if (outer) {
      relContent.push('noopener')
      relContent.push('noreferrer')
    }

    if (this.nofollow) {
      relContent.push('nofollow')
    }

    return {
      rel: relContent.join(', '),
      absolute,
      outer
    }
  },
  methods: {
    openNewsletter () {
      if (projectData.newsletter) {
        openAuthRequired.apply(this, [null, projectData.newsletter.feedback
          ? {
              name: 'modalMsg',
              params: [
                'Newsletter',
                projectData.newsletter.feedback
              ]
            }
          : null, 'modal', 'newsletter', true])
      }
    }
  }
}
