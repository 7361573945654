//
//
//
//


import cookieConsentCustom from '~/plugins/cookieconsent'
import project from '~/static/config/project.json'
import { customPageView } from '~/lib/gtm'

export default {
  name: 'LytRecommender',
  mounted () {
    if (project.plugins?.cookieconsent) {
      cookieConsentCustom(
        {
          onAccept: (cookie) => {
            if (cookie.categories.includes('analytics')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAnalytics' })
            }
            if (cookie.categories.includes('marketing')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAd' })
              window.googletag?.cmd.push(function () {
                window.googletag.pubads().setPrivacySettings({
                  limitedAds: false,
                  nonPersonalizedAds: false,
                  restrictDataProcessing: false
                })
              })
            }

            customPageView.apply(this, [document?.title, false])
            this.$store.dispatch('gtm/consent')
          }
        },
        this.$t('consent_cookie')
      )
    }
  }
}
