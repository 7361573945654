
export function customPageView (title, consent = true) {
  if (process.client && (this.$store.state.gtm.consent === consent)) {
    const fullPath = this.$route.fullPath.replace(/#.*$/, '')
    const eventProps = {
      event: 'CustomPageView',
      pageUrl: document.location.origin + fullPath,
      pagePath: fullPath,
      pageTitle: title || 'Runnea - (none)'
    }
    if (this.$nuxt.context.from?.fullPath) {
      eventProps.PageReferrer = document.location.origin + this.$nuxt.context.from.fullPath.replace(/#.*$/, '')
    }

    this.$gtm && this.$gtm.push(eventProps)
  }
}
