//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'
import AxiApi from '~/lib/axi'
import project from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

Vue.config.silent = true
export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ route, app, store, error, $axios, $config, $md5 }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api)

    // Params & Props
    const routeProps = route.matched[0].props.default
    const TypeConfig = Object.values(project.contents.product_types).find(
      pt => pt.id === routeProps.prodTypeId
    )
    TypeConfig.paths.product_prices = TypeConfig.paths.product_prices || project.contents.product_types.products.paths.product_prices
    const InquiriesPath = TypeConfig.paths.inquiries || project.contents.product_types.products.paths.inquiries

    const query = `${$config.static}/json/forum${route.path.replace('html', 'json')}`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', query)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const inquiry = store.state.lists[code]

    const zones = await $axios.get(
      $config.static + '/json/zones/inquiry/zones.json'
    )

    const inquiryComments = JSON.parse(JSON.stringify(inquiry.comments))

    inquiryComments.comments.forEach((comment) => {
      if (comment.wysiwyg && (comment.wysiwyg.length > 0)) {
        zones.data.zones['Comment_' + comment.content_id] = JSON.parse(JSON.stringify(comment.wysiwyg))
      }
    })

    await ax.createComponentData(zones.data, store)
    const zonesContent = ax.get()

    inquiryComments.comments.forEach((comment, iComment) => {
      if (zonesContent?.GlobalData?.zones['Comment_' + comment.content_id] && (zonesContent.GlobalData.zones['Comment_' + comment.content_id].length > 0)) {
        inquiryComments.comments[iComment].wysiwyg = zonesContent.GlobalData.zones['Comment_' + comment.content_id]
      }
    })

    const products = inquiry.products.filter(e => Object.keys(e).length > 0)// Eliminamos posibles emptys

    // Sections Data & Set Section
    const sectQry = $config.static + '/json/questions/sections.json'
    const sectCode = $md5(sectQry)
    if (typeof store.state.lists[sectCode] === 'undefined' || Object.keys(store.state.lists[sectCode]).length === 0) {
      await store.dispatch('loadLists', sectQry)
    }
    const sections = store.state.lists[sectCode]
    const section = sections.find(s => s.content_id === inquiry.section)

    // Metas, Head & Header
    const qst = inquiry.question ? inquiry.question.replace(':', '') : ''
    const tlt = inquiry.title ? inquiry.title.replace(':', '') : ''
    const title = `${qst !== '' ? qst : tlt}| ${app.i18n.t('inquiry_list.consultorio')}`
    const header = {
      h1: `${(inquiry.title) ? inquiry.title : inquiry.question?.replace(':', '') ?? `${app.i18n.t('index.query')} ${inquiry.content_id}`}`,
      h2: (section && `${section.metas.title.replace(/#producto#/g, '').replace(/#texto#/g, '')}`) || ''
    }
    const head = {
      title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: title
        },
        {
          hid: 'description',
          name: 'description',
          content: `${inquiry.title?.replace(/\.$/, '')}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path.replace(/outlet/, TypeConfig.paths.product_prices)}`
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'inquiry_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      TypeConfig,
      path: InquiriesPath,
      inquiry,
      inquiryComments,
      section,
      header,
      head,
      zones: zonesContent,
      products
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
